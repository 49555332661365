export const queryConfirmationPlannings = `query confirmation_plannings($query: Confirmation_planningQueryInput, $limit: Int, $sortBy: Confirmation_planningSortByInput){
  confirmation_plannings(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    confirmationsPerYear
    process {
      _id
      name
      evaluated {
        _id
        name
      }
      evaluator {
        _id
        name
      }
    }
    role {
      _id
      name
    }
    evaluated {
      _id
      name
    }
  }
}
`
export const queryConfirmationPlanningData = `query ConfirmationPlanningData($input: ConfirmationPlanningDatum!){
  ConfirmationPlanningData(input: $input) {
    totalRecords 
    confirmationPlannings {
      _id
      confirmationsPerYear
      role
      roleName {
        name
      }
      process
      processName {
        name
      }
      evaluated
      evaluatedName {
        name
      }
    }
  }
}
`

export const queryConfirmationPlanningGroupByRole = `query ConfirmationPlanningGroupedBy($input: ConfirmationPlanningGroupedBy!){
  ConfirmationPlanningGroupedBy(input: $input) {
    rolesData {
      _id
      planning {
        process
        confirmationsPerYear
      }
      confirmationsTotal
    }
  }
}
`

export const getConfirmationPlanning = `query confirmation_planning($query: Confirmation_planningQueryInput){
  confirmation_planning(query: $query) {
    _id
    role {
      _id
    }
    process {
      _id
    }
    confirmationsPerYear
    evaluated {
      _id
    }
  }
}
`

export const updateConfirmationPlanning = `mutation updateOneConfirmation_planning($query: Confirmation_planningQueryInput, $data: Confirmation_planningUpdateInput!) {
  updateOneConfirmation_planning(query: $query, set: $data) {
    _id,
  }
}
`

export const updateConfirmationPlannings = `mutation updateManyConfirmation_plannings($query: Confirmation_planningQueryInput, $data: Confirmation_planningUpdateInput!) {
  updateManyConfirmation_plannings(query: $query, set: $data) {
    matchedCount,
    modifiedCount,
  }
}
`

export const createConfirmationPlanning = `mutation insertOneConfirmation_planning($data: Confirmation_planningInsertInput!) {
  insertOneConfirmation_planning(data: $data) {
    _id
  }
}
`

export const createConfirmationPlannings = `mutation insertManyConfirmation_plannings($data: [Confirmation_planningInsertInput!]!) {
  insertManyConfirmation_plannings(data: $data) {
    insertedIds
  }
}
`